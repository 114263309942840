import { V6Client } from '@aws-amplify/api-graphql';
import { handleDateRangeValue } from './optimise';
import { TypesValuesAnalytics } from './format';
import {
  BusinessPrisma,
  GetHomepageMetricsQuery,
  GetHomepageMetricsQueryVariables,
} from 'API';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { generateCacheKey } from './generateCacheKey';
import { handleGraphqlResponse } from '@modules/handleGraphqlResponse';
import { getHomepageMetrics } from '@graphql/queries';

dayjs.extend(utc);
dayjs.extend(timezone);

export const prefetchAppData = async (
  client: V6Client<never>,
  selectedBusiness: BusinessPrisma | null,
) => {
  try {
    await prefetchOptimisePageData(client, selectedBusiness);
  } catch (error) {
    console.log(error);
  }
};

const prefetchOptimisePageData = async (
  client: V6Client<never>,
  selectedBusiness: BusinessPrisma | null,
) => {
  const preset: TypesValuesAnalytics =
    dayjs().diff(dayjs(selectedBusiness?.created_at), 'hours') < 24
      ? 'last_7_days'
      : 'last_30_days';
  const format = 'YYYY-MM-DD';

  const { start_date, end_date } = handleDateRangeValue(
    preset,
    selectedBusiness,
  );

  const variables = {
    getHomepageMetricsInput: {
      business_id: selectedBusiness?.id as string,
      dateStart: dayjs(start_date)
        .tz(selectedBusiness?.store?.timezone || 'America/Chicago')
        .format(format),
      dateEnd: dayjs(end_date)
        .tz(selectedBusiness?.store?.timezone || 'America/Chicago')
        .format(format),
    },
  };

  const cacheKey = generateCacheKey<GetHomepageMetricsQueryVariables>(
    'getHomepageMetrics',
    variables,
  );

  await handleGraphqlResponse<GetHomepageMetricsQuery>(
    () =>
      client.graphql({
        query: getHomepageMetrics,
        variables,
      }),
    cacheKey,
  );
};
