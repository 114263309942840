import * as Sentry from '@sentry/nextjs';
import { BusinessProfile } from '@interfaces/business';
import { BusinessConnections } from '@utils/types';
import { getUserDetails } from 'graphql/queries';
import { BusinessPrisma, GetUserDetailsQuery, UserPrisma } from '../API';

import { V6Client, V6ClientSSRCookies } from '@aws-amplify/api-graphql';
import { handleGraphqlResponse } from '@modules/handleGraphqlResponse';
import { SubscriptionStatuses } from '@utils/enums';

const fetchCurrentUserDetails = async (
  client: V6Client<never> | V6ClientSSRCookies<never>,
  businessVanityName?: string,
  businessId?: string,
) => {
  let userProfileData: UserPrisma | null = null,
    accountStatusData = false,
    businessListData: Array<BusinessPrisma | null | undefined> = [],
    businessCountData: number | null | undefined = null,
    businessActiveCountData: number | null | undefined = null,
    businessProfileData: BusinessProfile = {
      isLoading: false,
      userRole: 0,
      trackerStatus: false,
      profilePrisma: null,
    };
  const businessConnections: BusinessConnections = {
    facebook_ad_account_id: null,
    facebook_ad_account_name: null,
    shopify_store: null,
    tik_tok_ad_account_id: null,
    tik_tok_ad_account_name: null,
    shopify_script_tag_id: null,
    script_installed: false,
  };
  const selectedBusinessData: number | null = null;

  try {
    const userDetailsResponse =
      await handleGraphqlResponse<GetUserDetailsQuery>(() =>
        client.graphql({
          query: getUserDetails,
          variables: {
            getUserDetailsInput: {
              vanity_name: businessVanityName,
              business_id: businessId,
            },
          },
        }),
      );

    const userData = userDetailsResponse.data?.user;

    if (userData?.businesses && userData?.businesses?.length > 0) {
      const userBusiness =
        userData?.businesses?.find((item) => item.is_default_business)
          ?.business || userData.businesses[0].business;

      const latestSubscription = userBusiness.subscriptions?.at(-1);

      if (userBusiness?.status === 'verification_required') {
        accountStatusData = false;
      } else if (
        latestSubscription?.status !== 'active' &&
        latestSubscription?.status !== SubscriptionStatuses.TRIALING &&
        !userData.is_admin_user
      ) {
        accountStatusData = false;
      } else {
        accountStatusData = true;
      }
    }

    userProfileData = userData || null;

    /**
     * Admin users will always have access to the app and they don't need a subscription
     */
    if (userData?.is_admin_user) {
      accountStatusData = true;
    }
    //TODO later change this
    const roleid = 2;

    // if (userData?.manager_id) {
    //   roleid = 2;
    // } else {
    //   roleid = 1;
    // }

    const businessDetails = userDetailsResponse.data;

    const businesses = businessDetails?.businesses;

    businessListData = businesses?.business_list?.map((b) => b?.business) ?? [];
    businessCountData = businesses?.business_count;
    businessActiveCountData = businesses?.business_active_count;

    const business =
      businesses?.business_list?.find((item) => item?.is_default_business)
        ?.business || businessListData[0];
    const tracketStatus = businessDetails?.status?.active || false;

    if (business?.status === 'deactivated') {
      Sentry.captureException(new Error(JSON.stringify(business)));
    }

    businessProfileData = {
      isLoading: false,
      profile: business,
      profilePrisma: business,
      userRole: roleid,
      trackerStatus: tracketStatus,
    };
  } catch (error) {
    Sentry.captureException(error);

    businessProfileData = {
      isLoading: false,
      userRole: 0,
      trackerStatus: false,
      profilePrisma: null,
    };
  }

  return {
    userProfileData,
    accountStatusData,
    businessListData,
    businessCountData,
    businessActiveCountData,
    selectedBusinessData,
    businessProfileData,
    businessConnections,
  };
};

export default fetchCurrentUserDetails;
