/**
 * Generates a cache key based on provided base key and variables.
 *
 * This function takes a base key as a string and an object of variables. It sorts the variables by their keys
 * to ensure a consistent order, then constructs a string representation of these variables. For each variable,
 * if it's an object (including arrays), it is stringified using `JSON.stringify` to ensure a consistent,
 * portable representation. These key-value pairs are concatenated using a hyphen (`-`) as a delimiter.
 * Finally, the base key and the constructed string of variables are concatenated, separated by a hyphen,
 * to form the cache key.
 *
 * @param {string} baseKey - The base key to prepend to the variables string.
 * @param {T} variables - An object containing the variables to include in the cache key. It extends from
 * `Record<string, unknown>`, allowing for a wide range of types as values.
 * @returns {string} The generated cache key, combining the base key and sorted, stringified variables.
 *
 * @example
 * // Generates a cache key for a user with specific attributes
 * const userAttributes = { age: 30, name: "John Doe" };
 * const key = generateCacheKey("user", userAttributes);
 * // Possible output: "user-age:30-name:John Doe"
 *
 * @typeparam T - A generic type extending `Record<string, unknown>` to represent the variables object.
 */ export const generateCacheKey = <T extends Record<string, unknown>>(
  baseKey: string,
  variables: T,
): string => {
  // Sort the variables object by key to ensure consistent order
  const sortedVariablesKeys = Object.keys(variables).sort();
  const variablesString = sortedVariablesKeys
    .map((key) => {
      const value = variables[key as keyof T];
      // Convert complex objects to string if needed (e.g., arrays or objects)
      const valueString =
        typeof value === 'object' ? JSON.stringify(value) : value;
      return `${key}:${valueString}`;
    })
    .join('-');

  return `${baseKey}-${variablesString}`;
};
