import mixpanel from 'mixpanel-browser';

const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

const mixpanelInstance = () => {
  if (!MIXPANEL_TOKEN) {
    throw new Error(
      'Mixpanel token is missing. Please set NEXT_PUBLIC_MIXPANEL_TOKEN in your environment variables.',
    );
  }
  if (typeof window !== 'undefined' && MIXPANEL_TOKEN) {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: process.env.NODE_ENV !== 'production',
      track_pageview: true,
      ignore_dnt: true,
      persistence: 'localStorage',
    });
  }
  return mixpanel;
};

export default mixpanelInstance;

export const formatInsertId = (id: string) => {
  return id.replaceAll(' ', '_');
};
