import { BusinessPrisma } from 'API';
import { TypesValuesAnalytics } from './format';
import dayjs from 'dayjs';

export const handleDateRangeValue = (
  datePeriod: TypesValuesAnalytics,
  selectedBusiness: BusinessPrisma | null,
) => {
  let values: Record<TypesValuesAnalytics, Date>;

  const today = dayjs().tz(
    selectedBusiness?.store?.timezone || 'America/Chicago',
  );

  values = {
    today: today.toDate(),
    last_7_days: today.subtract(7, 'days').toDate(),
    last_30_days: today.subtract(30, 'day').toDate(),
  };

  const end_date = today.subtract(1, 'day').toDate();

  return {
    start_date: values[datePeriod],
    end_date: datePeriod === 'today' ? values[datePeriod] : end_date,
    graph_start_date:
      datePeriod === 'today'
        ? today.subtract(6, 'days').toDate()
        : values[datePeriod],
    graph_end_date: datePeriod === 'today' ? today.toDate() : end_date,
  };
};
