'use client';
import React, { useEffect, useState } from 'react';
import { signIn, signOut } from 'aws-amplify/auth';
import { usePathname, useRouter } from 'next/navigation';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { loginSchemaValidation } from 'utils/schemaValidations';
import Link from 'next/link';
import { LoadingButton } from '_components/LoadingButton';
import fetchCurrentUserDetails from 'api/fetchCurrentUserDetails';
import { setUserSessionNew } from '@graphql/mutations';
import * as Sentry from '@sentry/nextjs';
import { useBoundStore } from '@store/index';
import { Amplify } from 'aws-amplify';
import { libraryOptions, resourcesConfig } from '@graphql/aws-config';

import {
  deleteAllCookies,
  deleteBusinessIdIFromCookie,
  saveBusinessVanityNameInCookie,
  saveSessionIDInCookie,
} from 'app/action';
import { generateClient } from 'aws-amplify/api';
import { handleGraphqlResponse } from '@modules/handleGraphqlResponse';
import { BusinessPrisma, SetUserSessionNewMutation } from 'API';
import Image from 'next/image';
import InputErrorMessage from '_components/InputErrorMesssate';
import { prefetchAppData } from '_utils/prefetchAppData';
import mixpanelInstance from '@utils/mixpanel';
import { nanoid } from 'nanoid';

Amplify.configure(resourcesConfig, libraryOptions);

type LoginSchema = z.infer<typeof loginSchemaValidation>;

const LoginApp = () => {
  const { setPreviousPath } = useBoundStore((state) => state);
  const mixpanel = mixpanelInstance();
  const client = generateClient();
  const router = useRouter();
  const pathname = usePathname();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<LoginSchema>({
    resolver: zodResolver(loginSchemaValidation),
  });

  const [signingIn, setSigningIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { triggerFetch } = useBoundStore((state) => state);

  const checkUserLoggedIn = async () => {
    try {
      setSigningIn(true);

      const userDetails = await fetchCurrentUserDetails(client);

      if (userDetails?.userProfileData) {
        console.log('User logged in, redirecting to home');

        router.push(
          `/${userDetails?.businessProfileData?.profilePrisma?.vanity_name}/home`,
        );
        return;
      } else {
        console.log('User not logged in');
        setSigningIn(false);
      }
    } catch (error) {
    } finally {
      setSigningIn(false);
    }
  };

  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  const authenticate = async (email: string, password: string) => {
    setSigningIn(true);

    try {
      Amplify.configure(resourcesConfig, libraryOptions);

      await signOut();
      console.log('Called global sign out');
      await signIn({ username: email, password });

      saveUserSession();

      const search = window.location.search;
      const params = new URLSearchParams(search);
      const redirect_url = params.get('redirect');

      const userDetails = await fetchCurrentUserDetails(client);

      prefetchAppData(
        client,
        userDetails?.businessProfileData.profilePrisma as BusinessPrisma,
      );

      if (!userDetails.userProfileData) {
        throw new Error('Something went wrong');
      }

      if (userDetails?.businessProfileData?.profilePrisma?.id) {
        triggerFetch(
          `${userDetails?.businessProfileData.profilePrisma?.id}`,
          false,
        );
      }

      localStorage.setItem('openSidebar', JSON.stringify(true));
      await deleteBusinessIdIFromCookie();
      await saveBusinessVanityNameInCookie(
        userDetails?.businessProfileData?.profilePrisma?.vanity_name as string,
      );

      mixpanel.people.set({
        first_name: userDetails.userProfileData?.first_name,
        last_name: userDetails.userProfileData?.last_name,
        $email: userDetails.userProfileData?.email,
        business_name: userDetails?.businessProfileData?.profilePrisma?.name,
      });

      const sessionId = nanoid(10);
      await saveSessionIDInCookie(sessionId);

      mixpanel.track('session_start');
      localStorage.setItem('call_to_action_ads', 'false');
      localStorage.setItem('call_to_action_home', 'false');

      setPreviousPath('/login');

      if (redirect_url) {
        router.push(`${redirect_url}`);
      } else {
        router.push(
          `/${userDetails?.businessProfileData?.profilePrisma?.vanity_name}/home`,
        );
      }
    } catch (error) {
      let errorMessage = 'Unexpected error. Try again or reset password';
      const res = await deleteAllCookies();
      console.log('attempting clear cookies', res);

      if (error instanceof Error) {
        errorMessage =
          error?.name && error?.name === 'NotAuthorizedException'
            ? 'Wrong password. Try again or reset'
            : error.message;
      }

      setError('password', {
        message: errorMessage,
      });
      setSigningIn(false);
    }
  };

  const saveUserSession = async () => {
    try {
      await handleGraphqlResponse<SetUserSessionNewMutation>(() =>
        client.graphql({ query: setUserSessionNew }),
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const onSubmit: SubmitHandler<LoginSchema> = async (data) => {
    await authenticate(data.email, data.password);
  };

  return (
    <>
      <div className="flex flex-col grow p-4 pt-20 overflow-y-auto">
        <div className="max-w-xl mx-auto w-full">
          <div className="p-8 shadow-lg bg-white rounded-[20px]" data-testid="login-form">
            <h4 className="text-2xl font-ppFragment mb-6">Login</h4>
            <form onSubmit={handleSubmit(onSubmit)} className="relative">
              <label
                  className="form-label text-sm text-greyMidEmphasis "
                  htmlFor="email"
              >
                Email*
              </label>
              <input
                  className={`input input-rounded i-righ ${
                      errors.email && 'input-error'
                  } mb-4`}
                  placeholder="Enter Email Address"
                  type="email"
                  // id="email"
                  {...register('email')}
              />
              {errors.email && (
                  <span className="text-warningBody text-xs font-medium absolute left-0 top-[63px] flex">
                  <Image
                      src="/images/input-error.svg"
                      width={14}
                      height={14}
                      alt="eye-icon"
                      className="mr-1"
                  />
                    {errors.email?.message}
                </span>
              )}

              <label
                  className="form-label text-sm text-greyMidEmphasis relative mt-3"
                  htmlFor="password"
              >
                Password*
              </label>

              <input
                  className={`input input-rounded i-righ ${
                      errors.password && 'input-error'
                  }`}
                  placeholder="Enter Password"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password')}
              />

              <button
                  className="absolute mt-[13px] right-[10px] inline-flex text-base items-center justify-center text-darkGrade50 hover:text-darkGrade75"
                  role="button"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
              >
                <Image
                    src="/images/new-eye-icon.svg"
                    width={14}
                    height={14}
                    alt="eye-icon"
                />
              </button>

              <div className="flex items-start mt-1">
                {errors.password && (
                    <InputErrorMessage
                        message={errors.password?.message as string}
                    />
                )}

                <div className="ml-auto w-[110px] ">
                  <Link href="/forgot" className="link text-xs">
                    Forgot Password?
                  </Link>
                </div>
              </div>

              <div className="flex justify-end mt-6">
                {!signingIn ? (
                    <button className="btn primary w-[100px] h-[40px]">
                      Login
                    </button>
                ) : (
                    <LoadingButton text="Signing In..."/>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginApp;
