import {
  LibraryOptions,
  ResourcesConfig,
  AuthUserPoolConfig,
} from '@aws-amplify/core';

/** https://docs.amplify.aws/javascript/tools/libraries/configure-categories/ */

const cognitoConfig: AuthUserPoolConfig = {
  Cognito: {
    userPoolClientId: process.env.NEXT_PUBLIC_COGNITO_APP_CLIENT_ID || '',
    userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID || '',
    signUpVerificationMethod: 'code', // 'code' | 'link'
  },
};

const resourcesConfig: ResourcesConfig = {
  Auth: {
    ...cognitoConfig,

    /**
     * Does not fit v6 structure
     *
     *  // OPTIONAL - Configuration for cookie storage
     *  Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
     * cookieStorage: {
     *   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
     *   domain: '.yourdomain.com',
     *   // OPTIONAL - Cookie path
     *   path: '/',
     *   // OPTIONAL - Cookie expiration in days
     *   expires: 365,
     *   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
     *   sameSite: 'strict',
     *   // OPTIONAL - Cookie secure flag
     *   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
     *   secure: false,
     * },
     * cookieStorage: {
     *   domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
     *   // Set true if is a domain with https. For localhost set it to false
     *   secure: false,
     *   path: '/',
     *   expires: 2,
     * },
     *
     * // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
     * authenticationFlowType: 'USER_PASSWORD_AUTH',
     *
     * aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
     *
     */
  },
  API: {
    GraphQL: {
      endpoint: process.env.NEXT_PUBLIC_AWS_APPSYNC_GRAPHQL_ENDPOINT || '',
      defaultAuthMode: 'userPool',
      region: process.env.NEXT_PUBLIC_AWS_REGION,
      apiKey: process.env.NEXT_PUBLIC_AWS_APPSYNC_API_KEY,
    },
  },
};

const libraryOptions: LibraryOptions = {
  ssr: true,
};

const posthogConfig = {
  init: process.env.NEXT_PUBLIC_POSTHOG_INIT,
  url: process.env.NEXT_PUBLIC_POSTHOG_URL,
};

const reactPixelConfig = { init: process.env.NEXT_PUBLIC_REACTPIXEL_INIT };

export { resourcesConfig, libraryOptions, posthogConfig, reactPixelConfig };
