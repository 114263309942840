import Image from 'next/image';

type Props = {
  message: string;
};

const InputErrorMessage: React.FunctionComponent<Props> = ({ message }) => {
  return (
    <>
      <span className="text-warningBody text-xs font-medium flex">
        <Image
          src="/images/input-error.svg"
          width={14}
          height={14}
          alt="eye-icon"
          className="mr-1"
        />
        {message}
      </span>
    </>
  );
};

export default InputErrorMessage;
